import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var basePre = 'member-center';
var pre1 = 'customer';
var pre2 = 'membership';
var meta = {
  auth: true,
  closable: true
};
export default [{
  path: "/".concat(basePre, "/").concat(pre1),
  title: '客户管理',
  name: "".concat(pre1),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  // icon: 'md-speedometer',
  custom: 'icon-kehuguanli2_1',
  redirect: {
    name: "".concat(pre1, "-mgmt") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/").concat(pre1, "/customer-overview"),
    title: '会员概况',
    name: "".concat(pre1, "-overview"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '会员概况'
    }),
    component: "pages/".concat(basePre, "/").concat(pre1, "/customer-overview")
  }, {
    path: "/".concat(basePre, "/").concat(pre1, "/customer-mgmt"),
    title: '客户列表',
    name: "".concat(pre1, "-mgmt"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '客户列表'
    }),
    component: "pages/".concat(basePre, "/").concat(pre1, "/customer-mgmt")
  }]
}, {
  path: "/".concat(basePre, "/").concat(pre2),
  title: '会员卡管理',
  name: "".concat(pre2),
  header: "".concat(basePre),
  // icon: 'md-speedometer',
  custom: 'icon-huiyuanzhongxin2_1',
  redirect: {
    name: "".concat(pre2, "-card")
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/").concat(pre2, "/membership-card"),
    title: '会员卡方案',
    name: "".concat(pre2, "-card"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '会员卡方案'
    }),
    component: "pages/".concat(basePre, "/").concat(pre2, "/membership-card")
  }, {
    path: "/".concat(basePre, "/").concat(pre2, "/membership-grade"),
    title: '会员等级',
    name: "".concat(pre2, "-grade"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '会员等级'
    }),
    component: "pages/".concat(basePre, "/").concat(pre2, "/membership-grade")
  }, {
    path: "/".concat(basePre, "/").concat(pre2, "/integral"),
    title: '积分体系',
    name: "".concat(pre2, "-integral"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '积分体系'
    }),
    component: "pages/".concat(basePre, "/").concat(pre2, "/integral")
  }, {
    path: "/".concat(basePre, "/").concat(pre2, "/membership-operate"),
    title: '会员卡操作',
    name: "".concat(pre2, "-operate"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '会员卡操作'
    }),
    component: "pages/".concat(basePre, "/").concat(pre2, "/membership-operate")
  }]
}];