import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var basePre = 'operation-center';
var pre = 'closeloop';
var meta = {
  auth: true,
  closable: true,
  showMenu: false
};
export default [{
  path: "/".concat(basePre, "/").concat(pre, "/").concat(pre, "-order"),
  title: '闭环订单详情',
  name: "".concat(pre, "-order-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/").concat(pre, "-order"),
    title: '闭环订单详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/").concat(pre, "-order/order-detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/").concat(pre, "-goods"),
  title: '菜品闭环详情',
  name: "".concat(pre, "-goods-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/").concat(pre, "-goods"),
    title: '菜品闭环详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/").concat(pre, "-goods/goods-detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/concept-compensation"),
  title: '服务问题详情',
  name: "".concat(pre, "-concept-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/concept-compensation"),
    title: '服务问题详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/concept-compensation/concept-detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/customer-suggestion"),
  title: '顾客建议详情',
  name: "".concat(pre, "-suggestion-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/customer-suggestion"),
    title: '顾客建议详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/customer-suggestion/suggestion-detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/business-configuration"),
  title: '业务配置详情',
  name: "".concat(pre, "-business-configuration-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/business-configuration"),
    title: '业务配置详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/business-configuration/configuration-detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/closeloopshop-configuration-detail"),
  title: '菜品闭环管理详情',
  name: "".concat(pre, "-closeloopshops-configuration-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/closeloopshops-configuration"),
    title: '菜品闭环管理详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/closeloopshops-configuration/configuration-detail.vue")
}];