import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.replace";
import _toConsumableArray from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.array.find";
var inputNumber = {
  inserted: function inserted(el, binding) {
    var inputEl = _toConsumableArray(el.children).find(function (e) {
      return e.nodeName === 'INPUT';
    });
    if (inputEl._value === 0) {
      inputEl.value = '';
      inputEl._value = '';
    }
    el.oninput = function () {
      inputEl.value = inputEl.value.replace(/[^\d^.]+/g, '') // 非数字替换成空字符串
      // .replace(/^\./g, '') // 限制不能.开头
      .replace('.', '$#$') // 限制只能输入一个小数点
      .replace(/\./g, '').replace('$#$', '.');
      if (binding.arg && binding.arg === 'fixed' && inputEl.value) {
        if (inputEl.value.includes('.') && inputEl.value.charAt(inputEl.value.length - 1) !== '.') {
          inputEl.value = "".concat(inputEl.value.split('.')[0], ".").concat(inputEl.value.split('.')[1].slice(0, binding.value));
        }
        if (inputEl.value.includes('.') && inputEl.value.charAt(inputEl.value.length - 1) === '.' && binding.value === 0) {
          inputEl.value = "".concat(inputEl.value.split('.')[0]);
        }
      }

      // if (inputEl._value === '') {
      //     inputEl.value = 0
      // }
      // const customEvent = new CustomEvent('input', { value: 0 })
      // inputEl.dispatchEvent(customEvent)
      inputEl.dispatchEvent(new Event('input'));
      // if (inputEl._value === 0) {
      //     inputEl.value = ''
      // }
    };
    inputEl.addEventListener('blur', function () {
      if (inputEl._value === 0) {
        inputEl.value = '';
      }
    });
    // MutationObserver
  },
  componentUpdated: function componentUpdated(el) {
    // let inputEl = [...el.children].find(e => e.nodeName === 'INPUT')
    // setTimeout(() => {
    //     if (inputEl._value === 0) {
    //         inputEl.value = ''
    //     }
    // }, 100)
  }
};
export { inputNumber };