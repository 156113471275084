import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var basePre = 'coupon-center';
var pre = 'coupon';
var meta = {
  auth: true,
  closable: true
};
export default [{
  path: "/".concat(basePre),
  title: '券模板',
  name: "".concat(basePre),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  // icon: 'md-speedometer',
  custom: 'icon-kaquanzhongxin2_1',
  redirect: {
    name: "".concat(pre, "-template") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/coupon-template"),
    title: '优惠券列表',
    name: "".concat(pre, "-template"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '优惠券列表'
    }),
    component: "pages/".concat(basePre, "/coupon-template")
  }, {
    path: "/".concat(basePre, "/coupon-launch"),
    title: '券投放管理',
    name: "".concat(pre, "-launch"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '券投放管理'
    }),
    component: "pages/".concat(basePre, "/coupon-launch")
  }]
}, {
  path: "/".concat(basePre, "/card"),
  title: '礼品卡',
  name: "".concat(basePre, "-card"),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  // icon: 'md-speedometer',
  custom: 'icon-pinpai_1',
  redirect: {
    name: "".concat(pre, "-card") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/card-template"),
    title: '礼品卡模板',
    name: "".concat(pre, "-card"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '礼品卡模板'
    }),
    component: "pages/".concat(basePre, "/card-template")
  }, {
    path: "/".concat(basePre, "/card-exchange"),
    title: '兑换投放',
    name: "".concat(pre, "-card-exchange"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '兑换投放'
    }),
    component: "pages/".concat(basePre, "/card-exchange")
  }, {
    path: "/".concat(basePre, "/card-theme"),
    title: '礼品卡主题',
    name: "".concat(pre, "-card-theme"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '礼品卡主题'
    }),
    component: "pages/".concat(basePre, "/card-theme")
  }, {
    path: "/".concat(basePre, "/card-shelves"),
    title: '礼品卡货架',
    name: "".concat(pre, "-card-shelves"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '礼品卡货架'
    }),
    component: "pages/".concat(basePre, "/card-shelves")
  }]
}, {
  path: "/".concat(basePre, "/overview"),
  title: '全渠道',
  name: "".concat(basePre, "-overview"),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  // icon: 'md-speedometer',
  custom: 'icon-leixing_1',
  redirect: {
    name: "".concat(pre, "-overview") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/coupon-overview"),
    title: '全渠道',
    name: "".concat(pre, "-overview"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '全渠道'
    }),
    component: "pages/test-center"
  }]
}, {
  path: "/".concat(basePre, "/analyse"),
  title: '券分析',
  name: "".concat(basePre, "-analyse"),
  header: "".concat(basePre),
  // 生成的时候就是正确的 固定的
  // icon: 'md-speedometer',
  custom: 'icon-yunyingzhongxin2_1',
  redirect: {
    name: "".concat(pre, "-analyse") // 动态遍历取第一子级的第一个路由name
  },
  meta: meta,
  component: 'layouts/basic-layout',
  children: [{
    path: "/".concat(basePre, "/coupon-analyse"),
    title: '券分析',
    name: "".concat(pre, "-analyse"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '券分析'
    }),
    component: "pages/test-center"
  }]
}];