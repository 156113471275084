import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/*
 * @Description: 卡券中心-付费券包
 * @Author: rhd
 * @Date: 2024-09-13 11:29:56
 * @LastEditors: rhd
 * @LastEditTime: 2024-09-13 11:37:10
 */
var basePre = 'coupon-center';
var meta = {
  auth: true,
  closable: true,
  showMenu: false,
  activePath: "/".concat(basePre, "/coupon-package")
};
export default [{
  path: "/".concat(basePre, "/coupon-package-detail/:id?"),
  title: '付费券包详情',
  name: 'coupon-package-detail',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '付费券包详情'
  }),
  component: 'pages/coupon-center/coupon-package/detail.vue'
}, {
  path: "/".concat(basePre, "/coupon-package-edit/:type/:id?"),
  title: '新增付费券包',
  name: 'coupon-package-edit',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '新增付费券包'
  }),
  component: 'pages/coupon-center/coupon-package/edit.vue'
}];