import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var basePre = 'operation-center';
var pre = 'basic';
var meta = {
  auth: true,
  closable: true,
  showMenu: false,
  activePath: "/".concat(basePre, "/").concat(pre, "/store-list")
};
export default [{
  path: "/".concat(basePre, "/").concat(pre, "/store-manage/:type/:id?"),
  title: '新增门店',
  name: "store-manage-edit",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '新增门店'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/store-manage/store-edit.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/store-group/:type/:id?"),
  title: '新增门店分组',
  name: "store-group-edit",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '新增门店分组',
    activePath: "/".concat(basePre, "/").concat(pre, "/store-group")
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/store-manage/store-group/group-edit.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/store-area/:type/:id?"),
  title: '新增地区区域',
  name: "store-area-edit",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '新增地区区域',
    activePath: "/".concat(basePre, "/").concat(pre, "/store-area")
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/store-manage/store-area/area-edit.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/store-tag/:type/:id?"),
  title: '新增门店标签',
  name: "store-tag-edit",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '新增门店标签',
    activePath: "/".concat(basePre, "/").concat(pre, "/store-tag")
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/store-manage/store-tag/tag-edit.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/table-manage/:type/:id?"),
  title: '新增桌台类型',
  name: "table-template-edit",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '新增桌台类型',
    activePath: "/".concat(basePre, "/").concat(pre, "/table-template")
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/table-manage/table-template/template-edit.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/table-area/:type/:id?"),
  title: '新增桌台区域',
  name: "table-area-edit",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '新增桌台区域',
    activePath: "/".concat(basePre, "/").concat(pre, "/table-area")
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/table-manage/table-area/area-edit.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/meal-period/:type/:id?"),
  title: '新增餐段模板',
  name: "meal-period-edit",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '新增餐段模板',
    activePath: "/".concat(basePre, "/").concat(pre, "/meal-period")
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/meal-period/edit.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/store-table/:storeId"),
  title: '门店桌台管理',
  name: "table-manage-edit",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '门店桌台管理'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/table-manage/table-edit.vue")
}];