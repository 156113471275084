import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { on, off } from 'view-design/src/utils/dom';
import { setMatchMedia } from 'view-design/src/utils/assist';
import { mapMutations } from 'vuex';
setMatchMedia();
export default {
  name: 'app',
  methods: _objectSpread(_objectSpread({}, mapMutations('admin/layout', ['setDevice'])), {}, {
    handleWindowResize: function handleWindowResize() {
      this.handleMatchMedia();
    },
    handleMatchMedia: function handleMatchMedia() {
      var matchMedia = window.matchMedia;
      if (matchMedia('(max-width: 600px)').matches) {
        this.setDevice('Mobile');
      } else if (matchMedia('(max-width: 992px)').matches) {
        this.setDevice('Tablet');
      } else {
        this.setDevice('Desktop');
      }
    }
  }),
  mounted: function mounted() {
    // 自适应窗口（页面大小跟随屏幕尺寸自适应）
    if (window.screen.width >= 1200) {
      var bodyScale = function bodyScale() {
        var deviceWidth = document.documentElement.clientWidth;
        var scale = deviceWidth / 1920;
        document.body.style.zoom = scale;
      };
      window.onload = window.onresize = function () {
        bodyScale();
      };
    }
    on(window, 'resize', this.handleWindowResize);
    this.handleMatchMedia();
  },
  beforeDestroy: function beforeDestroy() {
    off(window, 'resize', this.handleWindowResize);
  }
};