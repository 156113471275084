import _toConsumableArray from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
// 菜单，侧边栏
// import dashboard from './modules/dashboard';
import member from "./modules/member";
import coupon from "./modules/coupon";
import operation from "./modules/operation";
import marketing from "./modules/marketing";
import trade from "./modules/trade";
import system from "./modules/system";

// 系统
// import log from './modules/log';

export default [].concat(_toConsumableArray(member), _toConsumableArray(coupon), _toConsumableArray(operation), _toConsumableArray(marketing), _toConsumableArray(trade), _toConsumableArray(system));