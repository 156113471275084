import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/*
 * @Description: 运营中心-订单管理-路由
 * @Author: rhd
 * @Date: 2024-08-01 15:51:46
 * @LastEditors: rhd
 * @LastEditTime: 2024-10-07 15:15:18
 */
var basePre = 'operation-center';
var pre = 'order';
var meta = {
  auth: true,
  closable: true,
  showMenu: false,
  activePath: "/".concat(basePre, "/").concat(pre, "/menu-order")
};
export default [{
  path: "/".concat(basePre, "/").concat(pre, "/menu-order-detail/:id"),
  title: '订单详情',
  name: "menu-order-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '订单详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/menu-order/menu-order-detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/integral-order-detail"),
  title: '订单详情',
  name: "integral-order-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '订单详情',
    activePath: "/".concat(basePre, "/").concat(pre, "/integral-order")
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/integral-order/integral-order-detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/recharge-order-detail/:id"),
  title: '订单详情',
  name: "recharge-order-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/recharge-order"),
    title: '订单详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/recharge-order/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/coupon-order-detail"),
  title: '订单详情',
  name: "coupon-order-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/coupon-order"),
    title: '订单详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/coupon-order/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/coupon-package-order-detail"),
  title: '订单详情',
  name: "coupon-package-order-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/coupon-package-order"),
    title: '券包订单详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/coupon-package-order/detail.vue")
}];