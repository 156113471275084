import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.date.to-string";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import _typeof from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import store from '@/store';
import axios from 'axios';
import util from '@/libs/util';
import Setting from '@/setting';
import ERROR_CODE from "./error.code.js";
import { Message, Notice } from 'view-design';
function isFormData(obj) {
  return _typeof(obj) === 'object' && obj instanceof FormData;
}
// 创建一个错误
function errorCreate(msg) {
  var err = new Error(msg);
  errorLog(err);
  throw err;
}

// 记录和显示错误
function errorLog(err) {
  // 添加到日志
  store.dispatch('admin/log/push', {
    message: '数据请求异常',
    type: 'error',
    meta: {
      error: err
    }
  });
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    util.log.error('>>>>>> Error >>>>>>');
    console.log(err);
  }
  // 显示提示，可配置使用 iView 的 $Message 还是 $Notice 组件来显示
  if (Setting.errorModalType === 'Message') {
    Message.error({
      content: err.message,
      duration: Setting.modalDuration
    });
  } else if (Setting.errorModalType === 'Notice') {
    Notice.error({
      title: '提示',
      desc: err.message,
      duration: Setting.modalDuration
    });
  }
}

// 创建一个 axios 实例
var service = axios.create({
  baseURL: Setting.apiBaseURL,
  timeout: 10000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(function (config) {
  // 在请求发送之前做一些处理
  if (!config.noNeedToken) {
    var token = util.cookies.get('token');
    // 三方登录
    var treeToken = window.localStorage.getItem('treeToken');
    if (treeToken) {
      // console.log('请求拦截器 window.localStorage.getItem-treeToken', treeToken)
      token = treeToken;
    }
    // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    config.headers['Authorization'] = 'Bearer ' + token;

    // 暂时取消登录-token有效期限制
    // let tokenFlag = false
    // const tokenInfo = util.cookies.get('tokenInfo');
    // if (tokenInfo) {
    //     const _tokenInfo = JSON.parse(tokenInfo)
    //     if (isWithinValidTime(_tokenInfo.now, _tokenInfo.expire)) {
    //         tokenFlag = true
    //     }
    // }

    // if (!tokenFlag) {
    //     console.log('token过期，退出登录')
    //     store.dispatch('admin/account/logout');
    // }
  }
  // integer  <int64> 卡类型品牌 可选
  var needTenant = !(config.notNeedTenant && config.notNeedTenant === true);
  if (needTenant && !(config.data && (config.data.tenantid || config.data.tenantId || isFormData(config.data)))) {
    if (store.state.admin && store.state.admin.brandInfo && store.state.admin.brandInfo.brandInfo && store.state.admin.brandInfo.brandInfo.tenantId) {
      config.data = _objectSpread(_objectSpread({}, config.data), {}, {
        tenantId: store.state.admin.brandInfo.brandInfo.tenantId
      });
      if (Object.prototype.toString.call(config.data.data) === '[object Object]') {
        config.data.data.tenantId = store.state.admin.brandInfo.brandInfo.tenantId;
      }
    }
  }
  // console.log('[请求拦截器 request]', config.url, config.data)
  return config;
}, function (error) {
  // 发送失败
  // console.log('请求拦截器 发送失败', error);
  Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(function (response) {
  // dataAxios 是 axios 返回数据中的 data
  var dataAxios = response.data;
  var config = response.config;
  console.log('config', config);
  // 是否返回原始响应数据
  if (config.headers.responseType && config.headers.responseType === 'rawData') {
    console.log('响应拦截器 service', dataAxios);
    return dataAxios;
  }
  // 这个状态码是和后端约定的
  // console.log('[响应拦截器 response]', dataAxios)
  if (dataAxios.header) {
    // 特殊处理 header 结构
    var _dataAxios$header = dataAxios.header,
      code = _dataAxios$header.code,
      message = _dataAxios$header.message;
    // 根据 code 进行判断
    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口
      return dataAxios;
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      if (code === 200 || code === 'ok' || code === 'Ok') {
        // code === 200 代表没有错误
        return dataAxios;
      } else if (message) {
        errorCreate(message);
      } else if (ERROR_CODE[code]) {
        // 其它和后台约定的 code message
        errorCreate(ERROR_CODE[code]);
      } else {
        errorCreate('未知异常');
      }
    }
  } else {
    var _code = dataAxios.code,
      data = dataAxios.data,
      _message = dataAxios.message;
    // 根据 code 进行判断
    if (_code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口
      return dataAxios;
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      if (_code === 200) {
        // code === 200 代表没有错误
        return data;
      } else if (_message) {
        // 其它和后台约定的 code message
        errorCreate(_message);
      } else if (ERROR_CODE[_code]) {
        // 其它和后台约定的 code message
        errorCreate(ERROR_CODE[_code]);
      } else {
        errorCreate('未知异常');
      }
    }
  }
}, function (error) {
  // console.log('响应拦截器 error', error)
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '请求错误';
        break;
      case 401:
        error.message = '未授权，请登录';
        store.dispatch('admin/account/logout');
        break;
      case 403:
        error.message = '拒绝访问';
        break;
      case 404:
        error.message = "\u8BF7\u6C42\u5730\u5740\u51FA\u9519: ".concat(error.response.config.url);
        break;
      case 408:
        error.message = '请求超时';
        break;
      case 500:
        error.message = '服务器内部错误';
        break;
      case 501:
        error.message = '服务未实现';
        break;
      case 502:
        error.message = '网关错误';
        break;
      case 503:
        error.message = '服务不可用';
        break;
      case 504:
        error.message = '网关超时';
        break;
      case 505:
        error.message = 'HTTP版本不受支持';
        break;
      default:
        break;
    }
  }
  errorLog(error);
  return Promise.reject(error);
});

// 检查是否在有效时间内
// function isWithinValidTime (createtime, expire) {
//     const createdAt = new Date(createtime).getTime();
//     const expireAt = createdAt + (expire * 1000);
//     const currentTime = new Date().getTime();

//     if (currentTime <= expireAt) {
//         return true;
//     } else {
//         return false;
//     }
// }

export default service;