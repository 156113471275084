import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'iHeaderCollapse',
  computed: _objectSpread({}, mapState('admin/layout', ['isMobile', 'isTablet', 'isDesktop', 'menuCollapse', 'showReload'])),
  methods: _objectSpread(_objectSpread({}, mapMutations('admin/layout', ['updateMenuCollapse'])), {}, {
    // 展开/收起侧边栏
    handleToggleMenuSide: function handleToggleMenuSide(state) {
      if (this.isMobile) {
        this.updateMenuCollapse(false);
        this.$emit('on-toggle-drawer', state);
      } else {
        this.updateMenuCollapse(!this.menuCollapse);
      }
    }
  }),
  watch: {
    // 切换页面时，在移动端自动收起侧边栏
    // 强行传参 false 是因为有的路由不是在菜单栏发生的，toggle 会使其显示
    '$route': function $route() {
      if (this.isMobile) this.handleToggleMenuSide(false);
    },
    // 在平板时自动收起菜单
    isTablet: function isTablet(state) {
      if (!this.isMobile && state) this.updateMenuCollapse(true);
    },
    // 在桌面时自动展开菜单
    isDesktop: function isDesktop(state) {
      if (!this.isMobile && state) this.updateMenuCollapse(false);
    }
  }
};